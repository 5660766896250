import * as Sentry from '@sentry/browser';
import { Image, Typography } from 'antd';
import { useEffect, useState } from 'react';
import styled from 'styled-components';
import { LogoIcon } from '../LogoIcon';
type Props = {
	children?: React.ReactElement | React.ReactElement[];
};

const FeedbackEnableCookies = ({ children }: Props) => {
	const [isEnableLocalStorage, setIsEnableLocalStorage] =
		useState<boolean>(true);

	useEffect(() => {
		try {
			window.localStorage.getItem('local-storage');
			setIsEnableLocalStorage(true);
		} catch (error) {
			console.error('FeedbackEnableCookies: ', { FeedbackEnableCookies });
			Sentry.captureException({ FeedbackEnableCookies: error });
			setIsEnableLocalStorage(false);
		}
	}, []);

	if (isEnableLocalStorage) {
		return <div>{children}</div>;
	}

	return (
		<Container>
			<div style={{ marginBottom: 30 }}>
				<LogoIcon />
			</div>
			<Typography.Title>
				¡Ups! <br />
				Cookies de terceros bloqueadas
			</Typography.Title>
			<Content>
				<Right>
					<Typography.Paragraph style={{ fontSize: 18 }}>
						Para habilitar las cookies de terceros en tu navegador,
						aquí tienes una guía sencilla para hacerlo. Por favor,
						sigue estos pasos:
					</Typography.Paragraph>
					<ErrorContainer>
						<Typography.Paragraph style={{ fontSize: '18px' }}>
							Chrome
							<a
								target="blank"
								href="https://support.google.com/chrome/answer/95647"
							>
								<span
									role="img"
									aria-label="dedo apuntando a la derecha"
								>
									👉
								</span>{' '}
								link
							</a>
						</Typography.Paragraph>
						<Typography.Paragraph style={{ fontSize: '18px' }}>
							Firefox
							<a
								target="blank"
								href="https://support.mozilla.org/en-US/kb/third-party-cookies-firefox-tracking-protection"
							>
								<span
									role="img"
									aria-label="dedo apuntando a la derecha"
								>
									👉
								</span>{' '}
								link
							</a>
						</Typography.Paragraph>
						<Typography.Paragraph style={{ fontSize: '18px' }}>
							Safari
							<a
								target="blank"
								href="https://support.apple.com/es-mx/guide/safari/ibrw850f6c51/mac"
							>
								<span
									role="img"
									aria-label="dedo apuntando a la derecha"
								>
									👉
								</span>{' '}
								link
							</a>
						</Typography.Paragraph>
					</ErrorContainer>

					<Typography.Paragraph style={{ fontSize: '18px' }}>
						Recuerda que habilitar las cookies de terceros puede
						mejorar tu experiencia de navegación en ciertos sitios
						web, pero es importante tener en cuenta tu privacidad y
						solo habilitarlas en sitios confiables. <br />
						¡Feliz navegación!
					</Typography.Paragraph>
				</Right>

				<Image
					src="https://cdn.hospy.co/images/cookies.png"
					preview={false}
					prefixCls="bot"
				/>
			</Content>
		</Container>
	);
};
export default FeedbackEnableCookies;

const Container = styled.div`
	padding: 50px 15px;
	max-width: 900px;
	margin: 0px auto;
`;

const Content = styled.div`
	display: flex;
	gap: 15px;

	.bot {
		width: 200px;
		right: 0;
		&-img {
			width: 100%;
		}
	}
	@media (max-width: 600px) {
		flex-direction: column-reverse;
		align-items: center;
	}
`;

const ErrorContainer = styled.div`
	border: 1px solid;
	border-radius: 4px;

	padding: 15px;
	margin: 30px 0;
`;

const Right = styled.div`
	width: calc(100% - 200px);
	@media (max-width: 600px) {
		width: 100%;
	}
`;
