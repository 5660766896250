import { Spin } from 'antd';
import { LogoIcon } from '../LogoIcon';

export const LoadingAppView = ({ hideLogo }: { hideLogo?: boolean }) => {
	return (
		<div
			style={{
				display: 'flex',
				justifyContent: 'center',
				alignItems: 'center',
				width: '100%',
				height: '100vh'
			}}
		>
			<div
				style={{
					display: 'flex',
					flexDirection: 'column',
					alignItems: 'center',
					width: '100%',
					maxWidth: 600
				}}
			>
				{!hideLogo && <LogoIcon width={200} />}

				<div style={{ margin: 50 }}>
					<Spin size="large" />
				</div>
			</div>
		</div>
	);
};
