import { useRoutes } from 'react-router-dom';
import lazyWithRetry from '../lazy-with-retry';

const PathNotFound = lazyWithRetry(
	() => import('@airbnb-pages/path-not-found')
);

const PageError = lazyWithRetry(() => import('@airbnb-pages/page-error'));

const Form = lazyWithRetry(
	() => import('@airbnb-pages/check-in-host-form/check-in-host-form')
);
const Identity = lazyWithRetry(() => import('@airbnb-pages/identity'));
const Redirect = lazyWithRetry(() => import('@airbnb-pages/redirect'));

export const AppRoute = () => {
	const element = useRoutes([
		{
			path: '/',
			element: <Redirect />
		},
		{
			path: '/identity',
			element: <Identity />
		},
		{
			path: ':propertyId',
			children: [
				{
					path: ':reservationCode',
					element: <Form />
				},
				{
					path: ':reservationCode/:price',
					element: <Form />
				},
				{
					path: ':reservationCode//:price',
					element: <Form />
				},
				{
					path: ':reservationCode/:price/:price1',
					element: <Form />
				},
				{
					path: '',
					element: <Form />
				}
			]
		},
		{
			path: '/error',
			element: <PageError />
		},
		{
			path: '*',
			element: <PathNotFound />
		},
		{
			path: '/not-found',
			element: <PathNotFound />
		}
	]);
	return element;
};
