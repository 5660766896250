/* eslint-disable @typescript-eslint/no-explicit-any */
import { Fetch } from './fetch';
import { RequestCreate, RequestFindOne } from './requests';
import { ResponseFailure, ResponseSuccess } from './responses';
import { ApiURL } from './util-url';

export const getCountryService = (filter?: string): Promise<any> =>
	new Promise((resolve, reject) => {
		const accessToken = localStorage.getItem('Hospy-idToken');

		const requestOptions = {
			method: 'GET',
			headers: {
				'Content-Type': 'application/json',
				Authorization: `Bearer ${accessToken}`
			}
		};

		fetch(
			ApiURL(`api/common/locations/countries?${filter || ''}`),
			requestOptions
		)
			.then((response) => response.json())
			.then((data) => resolve(data))
			.catch((error) => reject(error));
	});

export const getPropertybyFilterPublicService = (
	filter: string
): Promise<any> =>
	new Promise((resolve, reject) => {
		const requestOptions = {
			method: 'GET',
			headers: {
				'Content-Type': 'application/json'
			}
		};
		fetch(
			ApiURL(
				`/api/common/property?filter=${filter}&fields=["_id","name","location","currency","timezone"]`
			),
			requestOptions
		)
			.then((response) => response.json())
			.then((data) => resolve(data))
			.catch((error) => reject(error));
	});

export const postSendNotification = (
	type: 'whatsapp' | 'email',
	data: any,
	filter: string
): Promise<any> =>
	new Promise((resolve, reject) => {
		const accessToken = localStorage.getItem('Hospy-idToken');

		const url = ApiURL(
			`api/notifications/${type}/message-use-templates?filter=${filter}`
		);

		const requestOptions = {
			method: 'POST',
			headers: {
				'Content-Type': 'application/json',
				Authorization: `Bearer ${accessToken}`
			},
			body: JSON.stringify(data)
		};

		fetch(url, requestOptions)
			.then((response) => response.json())
			.then((data) => resolve(data))
			.catch((error) => reject(error));
	});

interface PostUpdateReservationFeedback {
	propertyId: string;
	partnerIntegrationId: string;
	appId: string;
	reservationId: string;
	feedback: string;
	feedbackComment?: string;
}

export const postUpdateReservationFeedbackService = ({
	propertyId,
	partnerIntegrationId,
	appId,
	reservationId,
	feedback,
	feedbackComment
}: PostUpdateReservationFeedback): Promise<any> =>
	new Promise((resolve, reject) => {
		const requestOptions = {
			method: 'POST',
			headers: {
				'Content-Type': 'application/json'
			},
			body: JSON.stringify({ feedback, feedbackComment })
		};

		const params = [
			`propertyId=${propertyId}`,
			`partnerIntegrationId=${partnerIntegrationId}`,
			`appId=${appId}`
		];

		fetch(
			ApiURL(
				`/api/pms/reservations/${reservationId}/feedback?${params.join(
					'&'
				)}`
			),
			requestOptions
		)
			.then((response) => response.json())
			.then((data) => resolve(data))
			.catch((error) => reject(error));
	});

interface UpdateReservationDataProps {
	propertyId: string;
	partnerIntegrationId: string;
	appId: string;
	sendEmail?: string;
	filter?: string;
}
export const updateReservationDataService = (
	id: string,
	props: UpdateReservationDataProps,
	data: any
): Promise<any> =>
	new Promise((resolve, reject) => {
		const searchParams = new URLSearchParams();
		for (const key in props) {
			if (Object.prototype.hasOwnProperty.call(props, key)) {
				const value = props[key as keyof UpdateReservationDataProps];
				if (value !== undefined) {
					searchParams.append(key, value);
				}
			}
		}

		const requestOptions = {
			method: 'PATCH',
			headers: {
				'Content-Type': 'application/json'
			},
			body: JSON.stringify(data)
		};
		fetch(
			ApiURL(`/api/pms/reservations/${id}?${searchParams.toString()}`),
			requestOptions
		)
			.then((response) => response.json())
			.then((data) => resolve(data))
			.catch((error) => reject(error));
	});

interface smartLocksInitGenerateAccessCodeServiceProps {
	propertyId: string;
	appId: string;
	data: any;
}
export const smartLocksInitGenerateAccessCodeService = ({
	propertyId,
	appId,
	data
}: smartLocksInitGenerateAccessCodeServiceProps): Promise<any> =>
	new Promise((resolve, reject) => {
		const accessToken = localStorage.getItem('Hospy-idToken');

		const url = ApiURL(
			`api/third-party/smartlock/smart-codes?propertyId=${propertyId}&appId=${appId}`
		);

		const requestOptions = {
			method: 'POST',
			headers: {
				'Content-Type': 'application/json',
				Authorization: `Bearer ${accessToken}`
			},
			body: JSON.stringify(data)
		};

		fetch(url, requestOptions)
			.then((response) => response.json())
			.then((data) => resolve(data))
			.catch((error) => reject(error));
	});

export const RequestUpsellingPurchaseIntent = (data: any): Promise<any> =>
	new Promise((resolve, reject) => {
		const accessToken = localStorage.getItem('Hospy-idToken');

		const requestOptions = {
			method: 'POST',
			headers: {
				Accept: 'application/json',
				'Content-Type': 'application/json',
				Authorization: `Bearer ${accessToken}`
			},
			body: JSON.stringify(data)
		};

		fetch(ApiURL(`/api/upselling/purchase-intent`), requestOptions)
			.then((response) => response.json())
			.then((data) => resolve(data))
			.catch((error) => reject(error));
	});

interface RequestUpsellingProductValidationProps {
	lang: 'es' | 'en' | 'pt';
	propertyId: string;
	reservationId: string;
	productId: string;
}
export const RequestUpsellingProductValidation = (
	data: RequestUpsellingProductValidationProps
): Promise<any> =>
	new Promise((resolve, reject) => {
		const accessToken = localStorage.getItem('Hospy-idToken');

		const requestOptions = {
			method: 'GET',
			headers: {
				Accept: 'application/json',
				'Content-Type': 'application/json',
				Authorization: `Bearer ${accessToken}`
			}
		};

		fetch(
			ApiURL(
				`/api/upselling/products/validations?lang=${data.lang}&propertyId=${data.propertyId}&reservationId=${data.reservationId}&productId=${data.productId}`
			),
			requestOptions
		)
			.then((response) => response.json())
			.then((data) => resolve(data))
			.catch((error) => reject(error));
	});

interface PostUpdateReservationIssues {
	propertyId: string;
	partnerIntegrationId: string;
	appId: string;
	reservationId: string;
	issues: any[];
}

export const postUpdateReservationIssuesService = ({
	propertyId,
	partnerIntegrationId,
	appId,
	reservationId,
	issues
}: PostUpdateReservationIssues): Promise<any> =>
	new Promise((resolve, reject) => {
		const requestOptions = {
			method: 'POST',
			headers: {
				'Content-Type': 'application/json'
			},
			body: JSON.stringify({ issues })
		};

		const params = [
			`propertyId=${propertyId}`,
			`partnerIntegrationId=${partnerIntegrationId}`,
			`appId=${appId}`
		];

		fetch(
			ApiURL(
				`/api/pms/reservations/${reservationId}/issues?${params.join(
					'&'
				)}`
			),
			requestOptions
		)
			.then((response) => response.json())
			.then((data) => resolve(data))
			.catch((error) => reject(error));
	});

export const getNotionPageService = (id: string): Promise<any> =>
	new Promise((resolve, reject) => {
		const requestOptions = {
			method: 'GET',
			headers: {
				'Content-Type': 'application/json'
			}
		};
		fetch(ApiURL(`/api/third-party/notion/pages/${id}`), requestOptions)
			.then((response) => response.json())
			.then((data) => resolve(data))
			.catch((error) => reject(error));
	});

export interface processDocumentProps {
	partnerIntegrationId: string;
	propertyId: string;
	reservationId: string;
	expectedDocument: string;
	eventType: string;
	eventName: string;
	data: string;
	contentType: string;
}
export const processDocumentService = (
	props: processDocumentProps
): Promise<any> =>
	new Promise((resolve, reject) => {
		const url =
			'https://hospy-scan-id-559814754656.us-central1.run.app/process-document';
		const requestOptions = {
			method: 'POST',
			headers: { 'Content-Type': 'application/json' },
			body: JSON.stringify(props)
		};
		fetch(url, requestOptions)
			.then((response) => response.json())
			.then((data) => resolve(data))
			.catch((error) => reject(error));
	});

interface GetReservationByIdProp {
	reservationId: string;
	propertyId: string;
	partnerIntegrationId: string;
	appId: string;
	responseRaw?: boolean;
}
export const getReservationDataByIdService = ({
	reservationId,
	propertyId,
	partnerIntegrationId,
	appId,
	responseRaw
}: GetReservationByIdProp): Promise<any> =>
	new Promise((resolve, reject) => {
		const requestOptions = {
			method: 'GET',
			headers: {
				'Content-Type': 'application/json'
			}
		};

		const params = [
			`propertyId=${propertyId}`,
			`partnerIntegrationId=${partnerIntegrationId}`,
			`appId=${appId}`
		];

		// eslint-disable-next-line eqeqeq
		if (responseRaw != undefined) {
			params.push(`responseRaw=${responseRaw}`);
		}
		fetch(
			ApiURL(
				`/api/pms/reservations/${reservationId}?${params.join('&')}`
			),
			requestOptions
		)
			.then((response) => response.json())
			.then((data) => resolve(data))
			.catch((error) => reject(error));
	});

export const RequestFindReservationByCode = (
	params: RequestFindOne
): Promise<ResponseSuccess<any> | ResponseFailure> =>
	Fetch('get', 'api/pms/reservations/search-by/:key/:value', params);

export const RequestUpsellingPaymentProcessHold = (
	id: string,
	process: 'accept' | 'cancel'
): Promise<any> =>
	new Promise((resolve, reject) => {
		const accessToken = localStorage.getItem('Hospy-idToken');
		const requestOptions = {
			method: 'GET',
			headers: {
				Accept: 'application/json',
				'Content-Type': 'application/json',
				Authorization: `Bearer ${accessToken}`
			}
		};

		const url = ApiURL(`/api/upselling/payment/${id}/${process}`);

		fetch(url, requestOptions)
			.then((response) => response.json())
			.then((data) => resolve(data))
			.catch((error) => reject(error));
	});

export const RequestPatchUpsellingPurchaseIntent = (
	id: string,
	data: any
): Promise<any> =>
	new Promise((resolve, reject) => {
		const accessToken = localStorage.getItem('Hospy-idToken');

		const requestOptions = {
			method: 'PATCH',
			headers: {
				Accept: 'application/json',
				'Content-Type': 'application/json',
				Authorization: `Bearer ${accessToken}`
			},
			body: JSON.stringify(data)
		};

		fetch(ApiURL(`/api/upselling/purchase-intent/${id}`), requestOptions)
			.then((response) => response.json())
			.then((data) => resolve(data))
			.catch((error) => reject(error));
	});

export const RequestUpsellingPaymentProcess = (id: string): Promise<any> =>
	new Promise((resolve, reject) => {
		const accessToken = localStorage.getItem('Hospy-idToken');

		const requestOptions = {
			method: 'GET',
			headers: {
				Accept: 'application/json',
				'Content-Type': 'application/json',
				Authorization: `Bearer ${accessToken}`
			}
		};

		fetch(ApiURL(`/api/upselling/payment/process/${id}`), requestOptions)
			.then((response) => response.json())
			.then((data) => resolve(data))
			.catch((error) => reject(error));
	});

interface RequestUpsellingPaymentHoldParams {
	lang: 'es' | 'en' | 'pt';
	purchaseIntentId: string;
	propertyId: string;
	propertyName: string;
	propertyType: string;
	countryId: string;
	countryName: string;
	cityId: string;
	cityName: string;
	reservationId: string;
	reservationThirdPartyId: string;
	pax: number;
	checkInDate: string;
	checkOutDate: string;
	productId: string;
	productName: { es: string; en: string };
	productDescription: { es: string; en: string };
	guestId: string;
	guestName: string;
	guestCountryId: string;
	guestCountryName: string;
	gender?: 'male' | 'female';
	phone: string;
	email: string;
	birthday: Date;
	reasonTrip: string;
	interests?: string[];
	settings: any;
	currency: string;
	channelSlug: string;
	channelName: string;
	rooms: { id: string; name: string }[];
	nights: number;
	reservationValue: number;
	nightValue: number;
	reservationCurrency: string;
	hostApprovalRequired: boolean;
	customCurrencyAmounts?: {
		currency: string;
		amount: number;
		serviceRate: number;
		hospyCommission: number;
		customerCommission: number;
		total: number;
	};

	amount?: number;
	serviceRate?: number;
	hospyCommission?: number;
	customerCommission?: number;
	total?: number;
}
export const RequestUpsellingPaymentHold = (
	data: RequestUpsellingPaymentHoldParams
): Promise<any> =>
	new Promise((resolve, reject) => {
		const accessToken = localStorage.getItem('Hospy-idToken');

		const requestOptions = {
			method: 'POST',
			headers: {
				Accept: 'application/json',
				'Content-Type': 'application/json',
				Authorization: `Bearer ${accessToken}`
			},
			body: JSON.stringify(data)
		};

		fetch(ApiURL(`/api/upselling/payment/hold`), requestOptions)
			.then((response) => response.json())
			.then((data) => resolve(data))
			.catch((error) => reject(error));
	});

export const RequestCreatePaymentHold = (
	params: RequestCreate
): Promise<ResponseSuccess<any> | ResponseFailure> =>
	Fetch('post', 'api/third-party/stripe/payment/hold', params);

export const validateIdDocumentDataServiceV2 = (data: {
	appId: string; // "63703fd5b7a852979f10e0d1",
	partnerIntegrationId: string; // "63f9340af275a92dcc6464c2",
	propertyId: string; // "64ac78553c2ba73443252c3d",
	resource: string; // "reservation",
	resourceId: string;
	name: string; // "demo.png",
	contentType: string; // "image/png",
	expectedDocument: string; // "mx-passport",
	data: string; //"",
	rekognition?: boolean;
	textract?: boolean;
	saveLog?: boolean;
	rekognitionVersion?: string;
	enhance?: boolean;
	confidence?: number;
	eventType?: string;
	eventName?: string;
}): Promise<any> =>
	new Promise((resolve, reject) => {
		let saveLog = data?.saveLog;
		if (data?.saveLog === undefined) {
			saveLog = true;
		}
		const rekognitionVersion = 'v3';

		if (data.enhance === undefined) {
			data.enhance = true;
		}
		const requestOptions = {
			method: 'POST',
			headers: {
				'Content-Type': 'application/json'
			},
			body: JSON.stringify({
				...data,
				saveLog,
				rekognitionVersion
			})
		};

		const env = process.env['NX_APP_SPA'] || '';
		let url =
			'https://db5c9x2vkc.execute-api.us-east-1.amazonaws.com/dev/api/id-documents/metadata';
		if (/https:\/\/hospy\.co/.test(env)) {
			url =
				'https://sc7em35apc.execute-api.us-east-1.amazonaws.com/prod/api/id-documents/metadata';
		}
		fetch(url, requestOptions)
			.then((response) => response.json())
			.then((data) => resolve(data))
			.catch((error) => reject(error));
	});

export const validateIdPhotoMatchService = (data: {
	appId: string;
	partnerIntegrationId: string;
	propertyId: string;
	resource: string; // "reservations"
	resourceId: string;
	eventType?: string; // "match-id"
	eventName?: string; //"Match ID  -  Pasaporte(Mexico)"
	source: string; //validation result path
	target: string; //selfie base64
}): Promise<any> =>
	new Promise((resolve, reject) => {
		const requestOptions = {
			method: 'POST',
			headers: {
				'Content-Type': 'application/json'
			},
			body: JSON.stringify(data)
		};

		const env = process.env['NX_APP_SPA'] || '';
		let url =
			'https://db5c9x2vkc.execute-api.us-east-1.amazonaws.com/dev/api/id-documents/match';
		if (/https:\/\/hospy\.co/.test(env)) {
			url =
				'https://sc7em35apc.execute-api.us-east-1.amazonaws.com/prod/api/id-documents/match';
		}

		fetch(url, requestOptions)
			.then((response) => response.json())
			.then((data) => resolve(data))
			.catch((error) => reject(error));
	});

export const getFieldMappingService = (filter: string): Promise<any> =>
	new Promise((resolve, reject) => {
		const accessToken = localStorage.getItem('Hospy-idToken');
		const requestOptions = {
			method: 'GET',
			headers: {
				'Content-Type': 'application/json',
				Authorization: `Bearer ${accessToken}`
			}
		};
		fetch(
			ApiURL(`/api/common/field-mapping?filter=${filter}`),
			requestOptions
		)
			.then((response) => response.json())
			.then((data) => resolve(data))
			.catch((error) => reject(error));
	});

export const getPropertyIntegrationsByPropertyPublicService = ({
	propertyId
}: {
	propertyId: string;
}): Promise<any> =>
	new Promise((resolve, reject) => {
		const requestOptions = {
			method: 'GET',
			headers: {
				'Content-Type': 'application/json'
			}
		};

		fetch(
			ApiURL(
				`/api/common/property-integrations?filter=${`{"propertyId":new ObjectId("${propertyId}")}&fields=["_id","propertyPartner", "extras", "property._id", "property.location", "property.currency","property.timezone", "partnerIntegrationId"]`}`
			),
			requestOptions
		)
			.then((response) => response.json())
			.then((data) => resolve(data))
			.catch((error) => reject(error));
	});

export const getPropertyPublicService = (propertyId: string): Promise<any> =>
	new Promise((resolve, reject) => {
		const requestOptions = {
			method: 'GET',
			headers: {
				'Content-Type': 'application/json'
			}
		};
		fetch(
			ApiURL(
				`/api/common/property?filter={"_id":new ObjectId("${propertyId}")}&fields=["_id","name","location","currency","timezone"]`
			),
			requestOptions
		)
			.then((response) => response.json())
			.then((data) => resolve(data))
			.catch((error) => reject(error));
	});

interface GetReservationProp {
	propertyId: string;
	partnerIntegrationId: string;
	appId: string;
	reservationCode?: string;
	reservationId?: string;
	filter?: string;
	fields?: string;
}

export const getReservationDataService = ({
	propertyId,
	partnerIntegrationId,
	appId,
	reservationCode,
	reservationId,
	filter,
	fields
}: GetReservationProp): Promise<any> =>
	new Promise((resolve, reject) => {
		const requestOptions = {
			method: 'GET',
			headers: {
				'Content-Type': 'application/json'
			}
		};

		const params = [
			`propertyId=${propertyId}`,
			`partnerIntegrationId=${partnerIntegrationId}`,
			`appId=${appId}`
		];

		if (reservationCode) {
			params.push(`filter={"thirdPartyId":"${reservationCode}"}`);
		}

		if (filter) {
			params.push(`filter=${filter}`);
		}

		if (fields) {
			params.push(`fields=${fields}`);
		}

		fetch(
			ApiURL(
				`/api/pms/reservations${
					reservationId ? '/' + reservationId : ''
				}?${params.join('&')}`
			),
			requestOptions
		)
			.then((response) => response.json())
			.then((data) => resolve(data))
			.catch((error) => reject(error));
	});

export const getWhatsappMeService = (params: any): Promise<any> =>
	new Promise((resolve, reject) => {
		const accessToken = localStorage.getItem('Hospy-idToken');
		const queryParams = new URLSearchParams(params).toString();
		const requestOptions = {
			method: 'GET',
			headers: {
				Accept: 'application/json',
				'Content-Type': 'application/json',
				Authorization: `Bearer ${accessToken}`
			}
		};

		fetch(
			ApiURL(`/api/notifications/whatsapp/me?${queryParams}`),
			requestOptions
		)
			.then((response) => response.json())
			.then((data) => resolve(data))
			.catch((error) => reject(error));
	});

interface PostInitAirbnbReservationProps {
	propertyId: string;
	partnerIntegrationId: string;
	appId: string;
	reservationId: string;
	filter: string;
	mode?: string;
}
export const postInitReservationService = ({
	propertyId,
	partnerIntegrationId,
	appId,
	reservationId,
	filter
}: PostInitAirbnbReservationProps): Promise<any> =>
	new Promise((resolve, reject) => {
		const requestOptions = {
			method: 'POST',
			headers: {
				'Content-Type': 'application/json'
			}
		};

		const params = [
			`propertyId=${propertyId}`,
			`partnerIntegrationId=${partnerIntegrationId}`,
			`appId=${appId}`
		];

		if (filter) {
			params.push(`filter=${filter}`);
		}

		fetch(
			ApiURL(
				`/api/pms/reservations/init/${reservationId}?${params.join(
					'&'
				)}`
			),
			requestOptions
		)
			.then((response) => response.json())
			.then((data) => resolve(data))
			.catch((error) => reject(error));
	});

export const RequestFindAllUpsellingProducts = (
	propertyId: string
): Promise<any> =>
	new Promise((resolve, reject) => {
		const accessToken = localStorage.getItem('Hospy-idToken');

		const requestOptions = {
			method: 'GET',
			headers: {
				Accept: 'application/json',
				'Content-Type': 'application/json',
				Authorization: `Bearer ${accessToken}`
			}
		};

		fetch(
			ApiURL(`/api/upselling/products?propertyId=${propertyId}`),
			requestOptions
		)
			.then((response) => response.json())
			.then((data) => resolve(data))
			.catch((error) => reject(error));
	});
