import { AppRoute } from '@airbnb/components/app-route';
import React from 'react';
import { LoadingAppView } from '../loading-app-view';

import ResizeObserver from 'resize-observer-polyfill';

// Comprueba si el navegador soporta ResizeObserver
if (!window.ResizeObserver) {
	window.ResizeObserver = ResizeObserver;
}

const App = () => {
	return (
		<React.Suspense fallback={<LoadingAppView hideLogo />}>
			<AppRoute />
		</React.Suspense>
	);
};

export default App;
