import { createAsyncThunk } from '@reduxjs/toolkit';
import { RequestCreate } from '../requests';
import {
	getFieldMappingService,
	getPropertyIntegrationsByPropertyPublicService,
	getPropertyPublicService,
	getReservationDataService,
	getWhatsappMeService,
	postInitReservationService,
	RequestCreatePaymentHold,
	RequestFindAllUpsellingProducts
} from '../services';

export const getAirbnbReservationProperty = createAsyncThunk(
	'airbnbReservation/property',
	async (propertyId: string, { rejectWithValue }) => {
		try {
			const { data, errors } = await getPropertyPublicService(propertyId);
			if (errors)
				return rejectWithValue(
					`Error al intentar cargar la información del negocio, ${errors}`
				);

			return data.length > 0 ? data[0] : undefined;
		} catch (error: any) {
			console.error({ error });
			return rejectWithValue(
				`Error al intentar cargar la información del negocio, ${error}`
			);
		}
	}
);

export const getAirbnbPropertyIntegration = createAsyncThunk(
	'airbnb/propertyIntegration',
	async (propertyId: string, { rejectWithValue }) => {
		try {
			const { data, errors } =
				await getPropertyIntegrationsByPropertyPublicService({
					propertyId
				});
			if (errors)
				return rejectWithValue(
					`Error al intentar cargar la información de la integración, ${errors}`
				);

			return data.length > 0
				? data.filter((x: any) => x?.property?._id === propertyId)
				: undefined;
		} catch (error: any) {
			console.error({ error });
			return rejectWithValue(
				`Error al intentar cargar la información de la integración, ${error}`
			);
		}
	}
);

interface GetAirbnbReservationProps {
	propertyId: string;
	partnerIntegrationId: string;
	appId: string;
	reservationCode: string;
}
export const getAirbnbReservation = createAsyncThunk(
	'airbnbReservation/reservation',
	async (props: GetAirbnbReservationProps, { rejectWithValue }) => {
		try {
			const { data, errors } = await getReservationDataService(props);
			if (errors)
				return rejectWithValue(`Error al cargar la reserva, ${errors}`);

			return data.length > 0 ? data[0] : undefined;
		} catch (error) {
			console.error({ error });
			return rejectWithValue(`Error al cargar la reserva, ${error}`);
		}
	}
);

export const getAirbnbReservationFieldMapping = createAsyncThunk(
	'getAirbnbReservation/field-mapping',
	async ({ propertyId, partnerIntegrationId }: any, { rejectWithValue }) => {
		try {
			const { data, errors } = await getFieldMappingService(
				`{propertyId:'${propertyId}', partnerIntegrationId:'${partnerIntegrationId}'}`
			);
			if (errors) return rejectWithValue(errors);
			return data[0];
		} catch (error: any) {
			return rejectWithValue(error);
		}
	}
);

export const getWhatsappMe = createAsyncThunk(
	'get/whatsapp-me',
	async (params: {
		propertyId: string;
		appId: string;
		partnerIntegrationId: string;
		partnerIntegrationIdTo: string;
	}) => {
		const { data } = await getWhatsappMeService(params);
		return data;
	}
);

interface PostInitAirbnbReservationProps {
	propertyId: string;
	partnerIntegrationId: string;
	appId: string;
	reservationId: string;
	filter: string;
}
export const initAirbnbReservation = createAsyncThunk(
	'airbnbReservation/initReservation',
	async (props: PostInitAirbnbReservationProps, { rejectWithValue }) => {
		try {
			const { data, statusCode, errors } =
				await postInitReservationService(props);
			if (errors || statusCode > 299 || statusCode < 200)
				return rejectWithValue(
					`Error al cargar la reserva, ${errors || ''}`
				);

			return data;
		} catch (error) {
			console.error({ error });
			return rejectWithValue(`Error al cargar la reserva, ${error}`);
		}
	}
);

export const ThunkCheckInUpsellingProducts = createAsyncThunk(
	`check-in-host/upselling-products`,
	async ({ propertyId }: { propertyId: string }, { rejectWithValue }) => {
		const response = await RequestFindAllUpsellingProducts(propertyId);
		if (response.statusCode === 200) return response.data;
		return rejectWithValue(response);
	}
);

export const ThunkCreatePaymentHold = createAsyncThunk(
	`check-in-host/create-payment-hold`,
	async (params: RequestCreate, { rejectWithValue }) => {
		const response = await RequestCreatePaymentHold(params);
		if (response.status === 'success') return response;
		return rejectWithValue(response);
	}
);
