import * as Sentry from '@sentry/react';
import { BrowserTracing } from '@sentry/tracing';
import * as ReactDOM from 'react-dom/client';
import { Provider } from 'react-redux';
import { BrowserRouter } from 'react-router-dom';
import './base-theme.css';
import App from './components/app/app';
import ErrorBoundaryFallback from './components/error-boundary-fallback';
import FeedbackEnableCookies from './components/feedback-enable-cookies';
import { GlobalStyles } from './components/global-styles';
import { HospyThemeProvider } from './components/hospy-theme-provider';
import './flags.css';
import { store } from './store';

// sentry campatibily
// https://developer.mozilla.org/en-US/docs/Web/JavaScript/Reference/Global_Objects/globalThis
Sentry.init({
	dsn: 'https://c058f14e9f7442b791e9b49312d806b6@o4504636114927616.ingest.sentry.io/4504996119117824',
	tracesSampleRate: 1.0,
	// This sets the sample rate to be 10%. You may want this to be 100% while
	// in development and sample at a lower rate in production
	//replaysSessionSampleRate: 0,
	// If the entire session is not sampled, use the below sample rate to sample
	// sessions when an error occurs.
	//replaysOnErrorSampleRate: 0,
	integrations: [
		new BrowserTracing(),
		//new Sentry.Replay()		
	],
	// environment: process.env['NODE_ENV'] || 'development'
	environment: window.location.href.includes('dev.hospy')
		? 'development'
		: 'production'
});

const root = ReactDOM.createRoot(
	document.getElementById('root') as HTMLElement
);
root.render(
	<Provider store={store}>
		<HospyThemeProvider>
			<GlobalStyles />
			<FeedbackEnableCookies>
				<BrowserRouter>
					<Sentry.ErrorBoundary
						fallback={(props) => (
							<ErrorBoundaryFallback {...props} />
						)}
					>
						<App />
					</Sentry.ErrorBoundary>
				</BrowserRouter>
			</FeedbackEnableCookies>
		</HospyThemeProvider>
	</Provider>
);
